export function isSwedishLicenseNumberValid(ssn: string): boolean {
  // Regex to match correct format (YY)YYMMDD(-/+)BBBC
  // (YY)YY - Birth year, could be four or two digits
  // MM - Birth month
  // DD - Birth day
  // (-/+) - Optional delimiter, default is '-' but '+' is used when older than 100 years
  // BBB - Personal numbers
  // C - Control number
  const regex = /^(18|19|20)?(\d{2})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])([-+])?(\d{4})$/
  const match = ssn.match(regex)

  if (!match) return false  // Invalid format

  return validateChecksum(match[2] + match[3] + match[4] + match[6])
}

// Checksum is calculated using the Luhn algorithm. If the sum modulo 10 is 0 the personal number is valid
function validateChecksum(numbers: string): boolean {
  let sum = 0
  let alternate = true
  let digits = numbers.split("").map(Number)

  for (let i = 0; i < 10; i++) {
      let n = digits[i]
      if (alternate) {
        n *= 2
        if (n > 9) n -= 9 // Sum of individual digits
      }
      sum += n
      alternate = !alternate
  }
  return sum % 10 === 0
}