export function isNorwegianLicenseNumberValid(ssn: string): boolean {
  // Regex to match correct format DDMMYYIIICC
  // DD - Birth day
  // MM - Birth month
  // YY - Birth year
  // II - Individual number
  // CC - Control numbers used for validation
  const regex = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])(\d{2})(\d{3})(\d{2})$/

  const match = ssn.match(regex)

  if (!match) return false // Invalid format

  const intArray = convertCharToIntArray(ssn)
  return isLastDigitValid(intArray) &&
          isSecondLastDigitValid(intArray)
}

function convertCharToIntArray(ssn: string): number[] {
  return ssn.split('').map(Number)
}

function isLastDigitValid(numbers: number[]): boolean {
  const digit: number = calculateLastDigit(numbers)
  return digit === numbers[numbers.length - 1]
}

function isSecondLastDigitValid(numbers: number[]): boolean {
  const digit: number = calculateSecondLastDigit(numbers)
  return digit < 10 && digit === numbers[numbers.length - 2]
}

function calculateLastDigit(numbers: number[]): number {
  const checksum: number =
    (5 * numbers[0] +
      4 * numbers[1] +
      3 * numbers[2] +
      2 * numbers[3] +
      7 * numbers[4] +
      6 * numbers[5] +
      5 * numbers[6] +
      4 * numbers[7] +
      3 * numbers[8] +
      2 * numbers[9]) %
    11
  return checksum === 0 ? checksum : 11 - checksum
}

function calculateSecondLastDigit(numbers: number[]): number {
  const checksum: number =
    (3 * numbers[0] +
      7 * numbers[1] +
      6 * numbers[2] +
      1 * numbers[3] +
      8 * numbers[4] +
      9 * numbers[5] +
      4 * numbers[6] +
      5 * numbers[7] +
      2 * numbers[8]) %
    11
  return checksum === 0 ? checksum : 11 - checksum
}
