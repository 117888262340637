export function isFinnishLicenseNumberValid(ssn: string): boolean {
  // Regex to match correct format DDMMYYCZZZQ
  // DD - Birth day
  // MM - Birth month
  // YY - Birth year
  // C - Century marker, + if born in 1800, -,U,V,W,X,Y,Z if born in 1900 and A,B,C,D,E,F if born in 2000. Latest current allowed birthday is 31/12/2019 which should be 18 years old 31/12/2037
  // ZZZ - Individual number
  // Q - Control value that could be any number or letter, excluding G, I, O, Q and Z
  const regex =  /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d{2}[-U-Y]|[01]\d[A-F])(\d{3}[0-9A-FHJ-NPR-Y])$/
  const match = ssn.toUpperCase().match(regex)

  if (!match) return false // Invalid format

  return validateChecksum(match[1] + match[2] + match[3].slice(0,2) + match[4])
}

// Validates that both the first 9 numbers (excluding centrury marker) and the Control value
// has the same value modulo 31
function validateChecksum(id: string): boolean {
  let value = 0

  if (/^[A-Z]$/.test(id.slice(-1))) {
    value = getVerificationCharValue(id.slice(-1))
  } else {
    value = Number(id.slice(-1))
  }

  return (Number(id.slice(0, -1)) % 31) == value
}

// Value of Control character according to the Finnish tax administration: 
// https://www.vero.fi/globalassets/tietoa-verohallinnosta/ohjelmistokehittajille/finnish-tax-administration_the-control-character-for-verifying-the-authenticity-of-finnish-business-ids-and-personal-identity-codes.pdf 
function getVerificationCharValue(char: string): number {
  switch(char.toUpperCase()) {
    case "A":
      return 10
    case "B":
      return 11
    case "C":
      return 12
    case "D":
      return 13
    case "E":
      return 14
    case "F":
      return 15
    case "H":
      return 16
    case "J":
      return 17
    case "K":
      return 18
    case "L":
      return 19
    case "M":
      return 20
    case "N":
      return 21
    case "P":
      return 22
    case "R":
      return 23
    case "S":
      return 24
    case "T":
      return 25
    case "U":
      return 26
    case "V":
      return 27
    case "W":
      return 28
    case "X":
      return 29
    case "Y":
      return 30
    default:
      return 0
  }
}